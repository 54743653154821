@use '../../assets/scss/custom';

// ROCKET NAME COLOURS
//$essence: #F47D30;
//$form: #8AD4DF;
//$space: #3D3935;

:root {
    --font-color: #333;
    --background-color: #eee;
    --link-color: custom.$form;
}

[data-theme="dark"] {
    --font-color: #eee;
    --background-color: #333;
    --link-color: lightblue;
}

body {
    background-color: var(--background-color);
    color: var(--font-color);
}

a {
    color: var(--link-color);
}

/* Custom Dark Mode Toggle Element */

.toggle-theme-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-theme-wrapper span {
    font-size: 28px;
    margin: 0 4px;
}

.toggle-theme {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
}

.toggle-theme input {
    display: none;
}

.slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
}

.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

input:checked + .slider {
    background-color: custom.$form;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
