
@use '../custom' as *;

@use '../components/mixins' as *;


/**
* ----------------------------------------
* un-blog-list
* ----------------------------------------
*/
.un-blog-list {
    .nav {
        .nav-item {
            .nav-link {
                display: block;
                text-decoration: none;
                padding: 0 20px;
                margin-bottom: 20px;

                .image-blog {
                    display: flex;
                    align-items: center;

                    img {
                        width: 80px;
                        height: 80px;
                        border-radius: 15px;
                        object-fit: cover;
                        margin-right: 15px;
                    }

                    .text-blog {
                        h2 {
                            font-size: 15px;
                            font-weight: 500;
                            color: var(--dark);
                            margin: 0;
                            margin-bottom: 8px;
                        }

                        .others {
                            display: flex;
                            column-gap: 15px;
                            flex-direction: row;

                            .time,
                            .views {
                                display: inline-flex;
                                align-items: center;
                                color: var(--secondary);

                                i {
                                    font-size: 17px;
                                    margin-right: 5px;
                                }

                                span {
                                    font-size: 13px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.item-blog-list {
    .nav-link {
        display: block;
        text-decoration: none;
        margin-bottom: 20px;
        padding: 0;

        .image-blog {
            display: flex;
            align-items: center;

            img {
                width: 80px;
                height: 80px;
                border-radius: 15px;
                object-fit: cover;
                margin-right: 15px;
            }

            .text-blog {
                h2 {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--dark);
                    margin: 0;
                    margin-bottom: 8px;
                }

                .others {
                    display: flex;
                    column-gap: 15px;
                    flex-direction: row;

                    .time,
                    .views {
                        display: inline-flex;
                        align-items: center;
                        color: var(--secondary);

                        i {
                            font-size: 17px;
                            margin-right: 5px;
                        }

                        span {
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

/**
* ----------------------------------------
* un-details-blog
* ----------------------------------------
*/
.un-details-blog {
    .head {
        padding: 0 20px;

        .cover_img {
            position: relative;

            img {
                width: 100%;
                height: 250px;
                border-radius: 15px;
                object-fit: cover;
            }
        }
    }

    .body {
        padding: 15px 20px;

        .title-blog {
            margin-bottom: 18px;

            .others {
                display: flex;
                column-gap: 15px;
                flex-direction: row;
                margin-bottom: 10px;

                .time,
                .views {
                    display: inline-flex;
                    align-items: center;
                    color: var(--secondary);

                    i {
                        font-size: 17px;
                        margin-right: 5px;
                    }

                    span {
                        font-size: 13px;
                        font-weight: 500;
                    }
                }
            }

            h2 {
                font-size: 22px;
                font-weight: 600;
                color: var(--dark);
                margin: 0;
            }
        }

        .description {
            p {
                font-size: 15px;
                color: var(--secondary);
            }
        }
    }

    .footer {
        background-color: var(--light);
        padding: 30px 20px;

        h4 {
            font-size: 20px;
            font-weight: 500;
        }
    }
}

/**
* ----------------------------------------
* bok-next-prev
* ----------------------------------------
*/

.bok-next-prev {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
        display: flex;
        align-items: center;

        .icon {
            width: 75px;
            height: 52px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            border: 1px solid var(--gray-200);
            font-size: 22px;
            position: relative;
        }

        span {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 11px;
        }

        &.prev {
            .icon {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                color: var(--secondary);

                &::before {
                    content: "";
                    width: 1px;
                    height: 25px;
                    background-color: var(--gray-200);
                    display: block;
                    position: absolute;
                    right: 0;
                }
            }

            span {
                color: var(--secondary);
                margin-right: 20px;
            }
        }

        &.next {
            .icon {
                border-left: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                color: var(--dark);
            }

            span {
                color: $primary;
                margin-left: 20px;
            }
        }
    }
}
