@use '../custom' as *;

@use '../components/mixins' as *;

/**
 * ----------------------------------------
 * un-item-seller
 * ----------------------------------------
 */
.un-item-seller {
    text-decoration: none !important;
    display: flex;
    align-items: center;

    .number {
        font-size: 13px;
        font-weight: 500;
        color: var(--gray-300);
        margin-right: 15px;
    }

    .media-profile {
        display: flex;
        align-items: center;

        .image-avatar {
            position: relative;
            margin: 0;
            margin-right: 15px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }

            .icon-verify {
                position: absolute;
                font-size: 16px;
                color: $primary;
                top: 30px;
                right: -4px;
            }
        }

        .text {
            h3 {
                font-size: 15px;
                font-weight: 500;
                color: var(--dark);
                margin: 0;
                margin-bottom: 3px;
            }

            p {
                font-size: 13px;
                font-weight: 500;
                color: var(--secondary);
                margin: 0;
            }
        }
    }
}

/**
 * ----------------------------------------
 * un-section-seller
 * ----------------------------------------
 */
.un-section-seller {
    .un-block-creators {
        .swiper-slide {
            width: 55%;
            background: transparent;

            @media (min-width: 240px) and (max-width: 375px) {
                width: 100%;
            }

            @media (min-width: 300px) and (max-width: 375px) {
                width: 70%;
            }
        }

        .un-item-seller {
            padding: 0 0 0 20px;
            width: 100%;
            text-align: left;
        }
    }
}
