@use '../custom' as *;

@use '../components/mixins' as *;

/*-----------------------------
  START THE OFFLINE CONTENT
-----------------------------*/

.offline-section {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    padding: 20px 50px;
    margin-top: 50px;
    .content-offline {
        .img-offline {
            margin-bottom: 30px;
            width: 175px;
            height: 115px;
            object-fit: contain;
        }
        .display-text {
            h2 {
                font-size: 20px;
                font-weight: 600;
                color: var(--dark);
                margin-bottom: 10px;
            }
            p {
                font-size: 14px;
                color: var(--secondary);
                margin-bottom: 30px;
            }
        }
    }
}