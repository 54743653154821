@use '../custom' as *;
@use 'mixins' as *;


// FLOATING
@include keyFrame(floating-1) {
    0% {
        transform: translate(0%, -15%);
    }

    50% {
        transform: translate(15%, 0%);
    }

    100% {
        transform: translate(0%, -15%);
    }
}

@include keyFrame(floating-2) {
    0% {
        transform: translate(0%, 15%);
    }

    50% {
        transform: translate(15%, 0%);
    }

    100% {
        transform: translate(0%, 15%);
    }
}

//  FADE OUT
@include keyFrame(fadeOut) {
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

//SK-SCALEOUT

@include keyFrame(sk-scaleout) {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

//ANIMATION CLICKABLE

@include keyFrame(animation_clickable) {
    to {
        transform: scale(4);
        opacity: 0;
    }
}


/**
 * ----------------------------------------
 * lds-spinner
 * ----------------------------------------
 */
 .lds-spinner {
    display: flex;
    justify-content: center;
    position: relative;
    width: calc(100% - 80px);
    height: 70px;
    margin-top: -20px;

    div {
        transform-origin: 40px 40px;
        animation: lds-spinner 1.2s linear infinite;

        &::after {
            content: " ";
            display: block;
            position: absolute;
            top: 27px;
            left: 39px;
            width: 2.3px;
            height: 7px;
            border-radius: 50px;
            background: var(--secondary);
        }

        &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: -1.1s;
        }

        &:nth-child(2) {
            transform: rotate(30deg);
            animation-delay: -1s;
        }

        &:nth-child(3) {
            transform: rotate(60deg);
            animation-delay: -0.9s;
        }

        &:nth-child(4) {
            transform: rotate(90deg);
            animation-delay: -0.8s;
        }

        &:nth-child(5) {
            transform: rotate(120deg);
            animation-delay: -0.7s;
        }

        &:nth-child(6) {
            transform: rotate(150deg);
            animation-delay: -0.6s;
        }

        &:nth-child(7) {
            transform: rotate(180deg);
            animation-delay: -0.5s;
        }

        &:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.4s;
        }

        &:nth-child(9) {
            transform: rotate(240deg);
            animation-delay: -0.3s;
        }

        &:nth-child(10) {
            transform: rotate(270deg);
            animation-delay: -0.2s;
        }

        &:nth-child(11) {
            transform: rotate(300deg);
            animation-delay: -0.1s;
        }

        &:nth-child(12) {
            transform: rotate(330deg);
            animation-delay: 0s;
        }
    }
}

@include keyFrame(lds-spinner) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}