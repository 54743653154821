@use "../custom" as *;

// BACKGROUND GRADIENT
@mixin bg-gradient {
    background-image: linear-gradient(to right, rgba($white, 1), rgba(#d977a7, 1), rgba(#8272e1, 1), rgba($white, 1));
}

@mixin gradient_right {
    background: linear-gradient(to right, rgba(#d977a7, 1), rgba(#8272e1, 1));
}

// OVERLAY MIXINS
@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// PREFIXER MIXINS
@mixin prefixer($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{"-" + $prefix + "-" + $property}: $value;
    }

    #{$property}: $value;
}

// KEYFRAME MIXINS
@mixin keyFrame($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @-moz-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

//FULL BOX SHADOW
@mixin shadow-full {
    @include prefixer(box-shadow, 0px 10vh 60vw 100vw rgba($dark, 0.2), webkit moz o ms);
}
//SMALL BOX SHADOW
@mixin shadow-small {
    @include prefixer(box-shadow, 0px 25px 70px -4px rgba($dark, 0.2), webkit moz o ms);
}

// CHANGE BACKGROUND AFTER SOME SECONDS

@include keyFrame(background-fade) {
    0% {
        background: $dark;
        color: $white;
    }
    100% {
        background:$white;
        color: $dark;
    }
}
