@use "../custom" as *;

@use "../components/mixins" as *;

/*-----------------------------
  darkMode-active
-----------------------------*/
[data-theme="dark"] {
  // WHITE AND GRAY
  --white: #3D3935;
  --secondary: #807d96;
  --light: #2a283b;
  //--dark: #{$gray-200};
  --dark: #BCB5B3;
  --gray-200: #353246;
  --gray-300: #{$gray-600};

  img:not([src*=".svg"]) {
    filter: grayscale(20%);
  }

  header {
    &.default {
      .un-block-right {
        .menu-sidebar {
          .btn {
            svg {
              rect {
                fill: $white;
              }
            }
          }
        }
      }

      &.border-b-gradient {
        &::before {
          background-image: linear-gradient(
                          to right,
                          rgba($dark, 1),
                          rgba(#d977a7, 1),
                          rgba(#8272e1, 1),
                          rgba($dark, 1)
          );
        }
      }
    }
  }

  .un-bottom-navigation {
    &.filter-blur {
      .em_body_navigation {
        background: rgba($space, 0.8);
      }
    }

    .item_link {
      .btn_navLink {
        &::after {
          background-color: $white;
        }

        &.active {
          .icon_active {
            i {
              color: $white;
            }
          }
        }
      }
    }
  }

  .un-item-logo {
    .logo-img {
      &.light-mode {
        display: none;
      }

      &.dark-mode {
        display: block;
      }
    }
  }

  .sidebarMenu {
    .modal-content {
      .nav-link {
        .xs-badge {
          background-color: rgba($white, 0.1) !important;
        }
      }
    }
  }

  .un-navMenu-default {
    .nav {
      .nav-item {
        .nav-link {
          &.visited {
            background-color: rgba($white, 0.015);
          }
        }
      }
    }
  }

  .nav-pilled-tab {
    background-color: #2c293e;
  }

  .slider-range-price {
    .noUi-tooltip {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8'%3E%3Cpath id='Polygon_3' data-name='Polygon 3' d='M7.293.707a1,1,0,0,1,1.414,0l5.586,5.586A1,1,0,0,1,13.586,8H2.414a1,1,0,0,1-.707-1.707Z' transform='translate(16 8) rotate(180)' fill='%237d7996'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .empty-items {
    .empty-dark {
      display: inline-block;
    }

    .empty-light {
      display: none;
    }
  }

  .footer-pages-forms {
    background: rgba($dark, 0.8);
  }

  .un-details-collectibles {
    .footer {
      background: rgba($dark, 0.8);
    }
  }

  .sidebarMenu {
    &.-left {
      .modal-dialog {
        .modal-content {
          .modal-header {
            .cover-balance {
              //background-color: #343247;
            }
          }
        }
      }
    }
  }

  .text-dark {
    color: $white !important;
  }

  .bg-white {
    background-color: $dark !important;
  }

  .un-creator-ptofile {
    .body {
      .item-followers {
        .users-text {
          .img-user {
            .link {
              color: $white;
              background-color: #3e3c4b;
            }
          }
        }
      }
    }
  }

  .form-group {
    .form-control {
      &:focus {
        border-color: #565567 !important;
      }
    }
  }
}
