@use '../custom' as *;

@use '../components/mixins' as *;

/**
 * ----------------------------------------
 * header
 * ----------------------------------------
 */

header {
    &.default {
        position: relative;
        min-height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--white);

        .un-title-page {
            h1 {
                font-size: 20px;
                font-weight: 600;
                color: var(--dark);
                margin: 0;
            }

            &.go-back {
                display: flex;
                align-items: center;

                .icon {
                    width: 30px;
                    height: 30px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 22px;
                    color: var(--dark);
                    background-color: var(--gray-200);
                    border-radius: 50%;
                    margin-right: 20px;
                    text-decoration: none !important;
                }
            }
            .user-publish {
                width: 24px;
                height: 24px;
                object-fit: cover;
            }
        }

        .un-item-logo {
            .logo-img {
                width: 202px;
                height: 42px;
                object-fit: contain;
            }
        }

        .un-block-right {
            display: flex;
            align-items: center;
            flex-direction: row;
            column-gap: 20px;

            .menu-sidebar {
                .btn {
                    padding: 0;
                    width: 30px;
                    height: 30px;
                    background-color: transparent;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 19px;
                    }
                }
            }

            .un-user-profile {
                a {
                    display: block;
                    text-decoration: none !important;

                    .img-avatar {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
            }

            .un-notification {
                position: relative;

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    text-decoration: none !important;
                    font-size: 22px;
                    color: var(--dark);

                    &.active {
                        i {
                            &::before {
                                content: "\EF99";
                            }
                        }
                    }
                }

                .bull-activity {
                    display: block;
                    width: 5px;
                    height: 5px;
                    background-color: $red;
                    border-radius: 30px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .btn-box-icon {
                width: 35px;
                height: 35px;
                font-size: 19px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: 0;
            }
        }

        &.border-b-gradient {
            &::before {
                content: "";
                position: absolute;
                height: 2px;
                bottom: 0;
                width: 100%;
                left: 0;
                right: 0;

                @include bg-gradient;
            }
        }

        &.heade-sticky {
            position: fixed;
            width: 100%;
            z-index: 99;
            background-color: var(--white);
        }
        &.header-light {

            background: rgba($dark, 0.5);
            -webkit-backdrop-filter: saturate(1) blur(20px);
            -o-backdrop-filter: saturate(1) blur(20px);
            -ms-backdrop-filter: saturate(1) blur(20px);
            backdrop-filter: saturate(1) blur(20px);


            .un-block-right {
              
                .menu-sidebar {
                    .btn {
                        padding: 0;
                        width: 30px;
                        height: 30px;
                        background-color: transparent;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
    
                        svg {
                            width: 19px;
                            rect {
                                fill: $white;
                            }
                        }
                    }
                }

            }
        }
    }
}

/**
 * ----------------------------------------
 * space-sticky
 * ----------------------------------------
 */
.space-sticky {
    height: 56px;
}

/**
 * ----------------------------------------
 * Dark Mode
 * ----------------------------------------
 */

.un-item-logo {
    .logo-img {
        &.dark-mode {
            display: none;
        }
    }
}
