@use "../custom" as *;

@use "../components/mixins" as *;

/**
 * ----------------------------------------
 * item-card-nft
 * ----------------------------------------
 */

.item-card-nft {
  position: relative;
  display: block;
  text-align: left;

  .body-card {
    padding: 0 20px;
    position: absolute;
  }

  .big-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .counterdown {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 8px 12px;
    border-radius: 50px;
    background-color: var(--white);
    font-size: 13px;
    font-weight: 500;
    color: var(--dark);
  }

  .un-info-card {
    background-color: var(--white);
    border-radius: 15px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
    left: 15px;
    right: 15px;

    .block-left {
      h4 {
        font-size: 18px;
        font-weight: 500;
        color: var(--dark);
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include media-breakpoint-down(sm) {
          width: calc(100vw - 67vw);
        }
      }

      .user {
        display: flex;
        align-items: center;

        h5 {
          font-size: 13px;
          color: var(--secondary);
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(sm) {
            width: calc(100vw - 78vw);
          }
        }

        .img-avatar {
          width: 25px;
          height: 25px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .block-right {
      text-align: right;

      h6 {
        font-size: 13px;
        color: var(--secondary);
        font-weight: 500;
        display: block;
        margin-bottom: 3px;
      }

      p {
        font-size: 15px;
        color: $green;
        font-weight: 500;
        margin: 0;

        span {
          font-size: 13px;
          margin-right: 3px;
        }
      }
    }
  }

  &.--swiper {
    .block-left {
      h4 {
        width: calc(100vw - 90vw);
        @include media-breakpoint-down(sm) {
          width: calc(100vw - 67vw);
        }
      }

      .user {
        h5 {
          @include media-breakpoint-down(sm) {
            width: calc(100vw - 78vw);
          }
        }
      }
    }
  }
}

.un-block-cards {
  .swiper {
    padding: 0 25px;
  }

  .item-card-nft {
    width: 100%;
    height: calc(100vh - 40vh);

    .big-image {
      border-radius: 15px;
    }
  }
}

/**
 * ----------------------------------------
 * item-card-gradual
 * ----------------------------------------
 */
.item-card-gradual {
  border-radius: 15px;
  border: 1px solid var(--gray-200);
  background-color: var(--bs-gray-100);

  .body-card {
    padding: 20px 20px 15px 20px;
    display: block;
    text-decoration: none !important;

    .cover-nft {
      position: relative;

      .img-cover {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        object-fit: cover;
      }

      .icon-type {
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 10px;
        background-color: rgba($dark, 0.3);
        font-size: 18px;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        inset: 0;
        margin: 15px;
      }

      .countdown-time {
        background-color: var(--white);
        border-radius: 50px;
        font-size: 13px;
        font-weight: 500;
        padding: 8px 12px;
        bottom: 15px;
        left: 15px;
        position: absolute;
        display: inline-block;
        color: var(--dark);
      }
    }

    .title-card-nft {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;

      .side-one {
        width: calc(100% - 80px);

        h2 {
          font-size: 24px;
          font-weight: 600;
          color: var(--dark);
          margin-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          color: var($space) !important;
        }
      }

      .side-other {
        .no-sales {
          font-size: 13px;
          display: block;
          font-weight: 500;
          color: $green;
        }
      }
    }
  }

  .creator-name {
    display: inline-flex;
    align-items: center;

    .image-user {
      position: relative;
      margin-right: 10px;

      .img-avatar {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
      }

      .icon {
        position: absolute;
        font-size: 16px;
        color: $primary;
        top: 15px;
        right: -4px;
      }
    }

    h3 {
      font-size: 13px;
      color: var(--dark);
      font-weight: 500;
      margin: 0;
    }
  }

  .head-card {
    padding: 15px 20px;
  }

  .footer-card {
    border-top: 1px solid var(--gray-200);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    .starting-bad {
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: var(--dark);
        margin-bottom: 3px;
      }

      span {
        font-size: 13px;
        color: var(--secondary);
        font-weight: 400;
        display: block;
      }
    }
  }
}

/**
 * ----------------------------------------
 * btn-like-click
 * ----------------------------------------
 */
.btn-like-click {
  display: inline-flex;
  align-items: center;
  align-items: center;

  .count-likes {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary);
    margin-right: 5px;

    @include prefixer(transition, 0.3s, webkit moz o ms);
  }

  .btnLike {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    input {
      position: absolute;
      font-size: 30px;
      opacity: 0;
      width: 70px;
      height: 30px;

      &:checked {
        ~ {
          i {
            &::before {
              content: "\EE0A";
              color: $red;

              @include prefixer(transition, 0.3s, webkit moz o ms);
            }
          }

          .count-likes {
            color: $red;
          }
        }
      }
    }

    i {
      font-size: 20px;
      color: var(--secondary);

      &::before {
        @include prefixer(transition, 0.3s, webkit moz o ms);
      }
    }
  }

  &.shape-box {
    input {
      width: 90px;
      height: 50px;

      &:checked {
        ~ {
          .icon-inside {
            background-color: rgba($red, 0.1);
            border-color: transparent;

            i {
              &::before {
                content: "\EE0A";
                color: $red;

                @include prefixer(transition, 0.3s, webkit moz o ms);
              }
            }
          }

          .count-likes {
            color: $red;
          }
        }
      }
    }

    .count-likes {
      margin-right: 10px;
    }

    .icon-inside {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--gray-200);

      @include prefixer(transition, 0.3s, webkit moz o ms);

      i {
        font-size: 19px;
        line-height: 0;
      }
    }
  }
}

/**
 * ----------------------------------------
 * unSwiper-cards
 * ----------------------------------------
 */

.unSwiper-cards {
  .content-cards-NFTs {
    .swiper-slide {
      width: 100%;
      background: transparent;

      //@include media-breakpoint-down(md) {
      //  width: 88% !important;
      //  &:first-child {
      //    .item-card-gradual {
      //      //margin-left: 20px;
      //    }
      //  }
      //}
      //
      //@include media-breakpoint-down(lg) {
      //  width: 88% !important;
      //}
      //
      //@include media-breakpoint-down(sm) {
      //  width: 93% !important;
      //}

      .swiper-slide:first-child {
        margin-left: 20px;
      }

      .item-card-gradual {
        width: 100%;
        margin-left: 20px;
        text-align: left;
      }

      &:last-child {
        margin-right: 20px;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    @include media-breakpoint-up(lg) {
      display: inline-flex;
    }
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--gray-200);
    color: var(--dark);
    @include prefixer(transition, 0.3s all, webkit moz o ms);

    &:hover {
      background-color: var(--dark);
      color: var(--white);
      box-shadow: 0 0 0px 6px rgba($dark, 0.1);
    }
  }

  .swiper-button-next {
    &::after {
      content: "\EA54";
      font-family: remixicon !important;
      font-size: 22px;
    }
  }

  .swiper-button-prev {
    &::after {
      content: "\EA52";
      font-family: remixicon !important;
      font-size: 22px;
    }
  }
}

/**
 * ----------------------------------------
 * discover-nft-random
 * ----------------------------------------
 */

.discover-nft-random {
  .content-NFTs-body {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    .item-card-nft {
      width: 100%;
      grid-column: 1/3;

      .big-image {
        border-radius: 15px;
        height: 355px;
      }

      .un-info-card {
        text-decoration: none !important;
      }

      .btn-like-click {
        background-color: var(--white);
        position: absolute;
        padding: 4px 12px;
        border-radius: 50px;
        top: 15px;
        right: 15px;
      }
    }

    // .grid-item {
    //     display: flex;
    //     flex-direction: row;
    //     column-gap: 10px;

    //     .item-sm-card-NFTs {
    //         width: calc(100% / 2);
    //     }
    // }
  }
}

/**
 * ----------------------------------------
 * item-sm-card-NFTs
 * ----------------------------------------
 */

.item-sm-card-NFTs {
  display: block;
  position: relative;
  text-decoration: none !important;

  .cover-image {
    position: relative;
    overflow: hidden;
    border-radius: 15px;

    .big-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 15px;
    }

    &::before {
      content: "";

      @include overlay;

      top: inherit;
      bottom: 0;
      height: 50%;
      background-image: linear-gradient(to top, rgba($dark, 1), rgba($dark, 0));
    }

    .content-text {
      .icon-type {
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 10px;
        background-color: rgba(34, 32, 50, 0.3);
        font-size: 18px;
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        inset: 0;
        margin: 15px;
      }

      .btn-like-click {
        background-color: var(--white);
        position: absolute;
        padding: 4px 12px;
        border-radius: 50px;
        top: 15px;
        right: 15px;
      }
    }

    .user-text {
      position: absolute;
      bottom: 15px;
      left: 15px;

      .user-avatar {
        margin-bottom: 5px;

        .sm-user {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 10px;
        }

        span {
          font-size: 13px;
          font-weight: 500;
          color: $white;
        }
      }

      .number-eth {
        span {
          color: $white;
          font-size: 11px;
          font-weight: 500;

          &.main-price {
            font-size: 14px;
            margin-right: 3px;
          }
        }
      }
    }
  }
}

/**
 * ----------------------------------------
 * discover-NFTs-gradual
 * ----------------------------------------
 */
.discover-NFTs-gradual {
  padding: 0 20px;

  .item-card-gradual {
    margin-bottom: 15px;
  }
}

/**
 * ----------------------------------------
 * discover-NFTs-gradual
 * ----------------------------------------
 */
.collection-creatores {
  .content-list-creatores {
    padding: 0 !important;

    .nav-item {
      &:first-of-type {
        .nav-link {
          padding-top: 0 !important;
        }
      }

      .nav-link {
        padding: 20px !important;
        display: block !important;
        text-decoration: none !important;

        .collection-img {
          display: flex;
          flex-direction: row;
          column-gap: 5px;
          align-items: center;
          margin-bottom: 18px;

          picture {
            width: 100%;
            max-width: calc(100% / 5);
          }

          .img-cot {
            width: 100%;
            height: 70px;
            border-radius: 10px;
            object-fit: cover;
          }
        }

        .item-user-img {
          text-decoration: none !important;

          .wrapper-image {
            position: relative;

            .icon {
              position: absolute;
              bottom: -10px;
              right: 9px;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
