// ROCKET NAME COLOURS
$essence: #F47D30;
$form: #8AD4DF;
$space: #3D3935;
$white-space: #EFEEED;

// MY THEME COLORS
$blue:    #395aff;
$indigo:  #6610f2;
$purple:  #a659ff;
$pink:    #e05fc4;
$red:     #ff3838;
$orange:  #ff7e1b;
$yellow:  #ffc21d;
$green:   #3ECA76;
$teal:    #20c997;
$cyan:    #20bbd3;

// WHITE AND GRAY

$white:    $white-space;
$gray-100: #f4f5f7; //LIGHT
$gray-200: #dde1ec; //BORDER
$gray-300: #b3b1c3; //PLACEHOLDER
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #5b586e; //TEXT COLOR
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #222032; //DARK
$black:    #000;

//MY THEME COLORS VARIABLES

$primary:       $blue;
$secondary:     $gray-600;
$light:         $gray-100;
$dark:          $gray-900;

// ROOT
:root {
    --secondary: #{$secondary};
    --light: #{$light};
    --dark: #{$space};
    --white: #{$white};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --black: #{$black};
    --primary: #{$primary};
}




//IMPORT BOOTSTRAP 5
@import "bootstrap/bootstrap";