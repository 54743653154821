@use "../custom" as *;

@use "../components/mixins" as *;

/*
    @FILE: DEFAULT STYLE
    @AUTHER: HASAN SIBAKHI
    @URL: https://dribbble.com/HasanSib

    THIS FILE CONTAINS THE STYLING FOR THE ACTUAL TEMPLATE, THIS
    IS THE FILE YOU NEED TO EDIT TO CHANGE THE LOOK OF THE
    TEMPLATE.

    =================================================================

    @TEMPLATE NAME: Unic – NFT Marketplace PWA Mobile Template
    @AUTHER: SHORTMESSAGE
    @DEVELOPED BY: HASAN SIBAKHI
    @DEVELOPED URL: https://dribbble.com/HasanSib
*/

/*
    // IMPORT 'Poppins' FROM GOOGLE FONTS
    
    REGULAR   :   400
    MEDIUM    :   500
    SEMIBOLD  :   600
    BOLD      :   700

    // THESE ARE THE NAMES IF YOU ARE USING A DESIGN FIILE SUCH AS FIGMA, ADOBE XD.
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.cdnfonts.com/css/exo-20');

body {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--white);
    min-height: 100vh;
    font: {
        family: "Exo 2.0", sans-serif;
        size: 14px;
        weight: 400;
    }
    color: var(--dark);

    @include prefixer(transition, 0.2s all, webkit moz o ms);

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
}

html {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; /* Firefox */
}

#wrapper,
#content {
    width: 100%;
    height: 100%;
}

button,
input,
textarea {
    outline: 0 !important;
    box-shadow: none !important;

    @include prefixer(box-shadow, none, webkit moz o ms);
}

.btn {
    &:hover {
        color: inherit;
    }
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
    outline: 0 !important;
}

img {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
}

::selection {
    background-color: var(--dark);
    color: var(--white);
}

i {
    display: inline-block;
}

/*============================
  GLOBAL - SWIPER
============================*/
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/*============================
  DIVIDAR
============================*/
.dividar {
    border-bottom: 1px solid var(--gray-200);
}

/*============================
  LOADER PAGE
============================*/
.loader-page {
    position: fixed !important;

    @include overlay;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    z-index: 9999;

    &.hidden {
        animation: fadeOut 1s;
        animation-fill-mode: forwards;
        pointer-events: none;
    }
}

.spinner_flash {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: var(--dark);
    border-radius: 100%;

    @include prefixer(animation, sk-scaleout 1s infinite ease-in-out, webkit moz o ms);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.env-pb {
    padding-bottom: env(safe-area-inset-bottom);
}

.env-pt {
    padding-top: env(safe-area-inset-top);
}

.env-pr {
    padding-right: env(safe-area-inset-right);
}

.env-pl {
    padding-left: env(safe-area-inset-left);
}

// iOS
.ios-env-pb {
    @supports (-webkit-touch-callout: none) {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.ios-env-pt {
    @supports (-webkit-touch-callout: none) {
        padding-top: env(safe-area-inset-top);
    }
}

.ios-env-pr {
    @supports (-webkit-touch-callout: none) {
        padding-right: env(safe-area-inset-right);
    }
}

.ios-env-pl {
    @supports (-webkit-touch-callout: none) {
        padding-left: env(safe-area-inset-left);
    }
}

.border {
    border: 1px solid var(--gray-200) !important;
}

// SCROLL TOP
.scrollTop {
    .scroll-top {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 50px;
        right: 30px;
        z-index: 99;
        cursor: pointer;
        border-radius: 50%;
        background-color: var(--gray-200);
        color: var(--dark);
        font-size: 22px;
        opacity: 0;
        @include prefixer(transform, translateY(50px), webkit moz o ms);
        @include prefixer(transition, all 0.3s ease, webkit moz o ms);
        pointer-events: none;
        &::before {
            content: "";
            width: 50px;
            height: 50px;
            position: absolute;
        }
        &.--center {
            left: 50%;
        }
        &.--left {
            left: 30px;
            right: inherit;
        }
        &.showButton {
            opacity: 1;
            pointer-events: all;
            @include prefixer(transform, translateY(0), webkit moz o ms);
        }
    }
}

.scrollspy-example {
    position: relative;
    height: 210px;
}


hr {
    background-color: var(--gray-200);
    opacity: 1;
}

//START THE STORIES SECTION
.border-sections-home {
    border-bottom: 1px solid var(--gray-200);
    width: 100%;
}