@use "../custom" as *;

@use "mixins" as *;

/*============================
  START THE BADGES
============================*/
.badge {
  border-radius: 6px;
  padding: 6px;
}