
@use '../custom' as *;

@use 'mixins' as *;


/**
* ----------------------------------------
* Buttons Sizes
* ----------------------------------------
*/

.btn-xs-size {
    min-width: 62px;
    height: 28px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.border-primary {
        border: 1px solid $primary;
    }
}

.btn-sm-size {
    min-width: 86px;
    height: 36px;
    padding-left: 18px;
    padding-right: 18px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.border-primary {
        border: 1px solid $primary;
    }
}

.btn-md-size {
    min-width: 95px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &.border-primary {
        border: 1px solid $primary;
    }
}

.effect-click {
    position: relative;
    overflow: hidden;
    z-index: 1;

    span {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        animation: animation_clickable 600ms linear;
        background-color: rgba($dark , 0.2);
    }
}

/**
 * ----------------------------------------
 * btn-bid-items
 * ----------------------------------------
 */
 .btn-bid-items {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 190px;
    height: 60px;
    border-radius: 50px;
    background-color: $primary;
    padding: 10px 10px 10px 20px;

    p {
        font-size: 15px;
        font-weight: 500;
        color: $white;
        margin: 0;
    }

    .ico {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $white;
        background-color: rgba($white, 0.12);
        border-radius: 50%;
    }
}


/**
* ----------------------------------------
* btn-box-check
* ----------------------------------------
*/

.btn-box-check {
    position: relative;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .icon-box {
        width: 35px;
        height: 35px;
        border-radius: 10px;
        background-color: $primary;
        font-size: 18px;
        color: $white;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include prefixer(transition, 0.25s, webkit moz o ms);
    }

    input {
        position: absolute;
        font-size: 40px;
        width: 35px;
        height: 35px;
        opacity: 0;

        &:checked {
            ~ {
                .icon-box {
                    background-color: var(--light);
                    color: var(--dark);

                    i {
                        &::before {
                            content: "";
                        }
                    }
                }
            }
        }
    }
}

/**
 * ----------------------------------------
 * item-add-NFTs-plus
 * ----------------------------------------
 */
 
 .item-add-NFTs-plus {
    position: fixed;
    bottom: 40px;

    a {
        width: 56px;
        text-decoration: none;
        height: 56px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: $primary;
        color: $white;
        font-weight: 600;
        font-size: 23px;
    }
}


/**
 * ----------------------------------------
 * btn-sm-arrow
 * ----------------------------------------
 */

 .btn-sm-arrow {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 165px;
    height: 55px;
    border-radius: 50px;
    padding: 10px 10px 10px 20px;

    p {
        font-size: 15px;
        font-weight: 500;
        color: $white;
        margin: 0;
        padding-right: 20px;

        span {
            font-size: 11px;
        }
    }

    .ico {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: $white;
        background-color: rgba($white, 0.12);
        border-radius: 50%;
    }
}

/**
 * ----------------------------------------
 * btn-md-arrow
 * ----------------------------------------
 */
.btn-md-arrow {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: 190px;
    height: 60px;
    border-radius: 50px;
    padding: 10px 10px 10px 20px;

    p {
        font-size: 15px;
        font-weight: 500;
        color: var(--white);
        margin: 0;
        padding-right: 20px;
    }

    .ico {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: var(--white);
        background-color: rgba(255, 255, 255, 0.12);
        border-radius: 50%;
    }
}
