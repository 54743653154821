@use '../custom' as *;
@use 'mixins' as *;

/*============================
 BORDER RADIUS ==> 20
============================*/
@for $i from 0 through 20 {
    .rounded-#{$i} {
        border-radius: (0px + $i) !important;
    }
}