@use '../custom' as *;

@use '../components/mixins' as *;


/*-----------------------------
  un-bottom-navigation
-----------------------------*/
.un-bottom-navigation {
    padding-top: 56px;

    // ios Devices
    @supports (-webkit-touch-callout: none) {
        padding-top: 90px;
    }

    .em_body_navigation {
        background-color: var(--white);
        min-height: 56px;
        position: fixed;
        width: 100%;
        bottom: -1px;
        left: 0;
        right: 0;
        z-index: 99;
        border-top: 1px solid var(--gray-200);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        padding-bottom: env(safe-area-inset-bottom);
        padding-left: 20px;
        padding-right: 20px;

        // @include minSmall {
        //     min-height: 60px;
        // }

        @include media-breakpoint-up(sm) {
            min-height: 60px;
        }


        .item_link {
            // width: calc(100% / 5);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 56px;

            .btn_navLink {
                width: 45px;
                height: 45px;
                display: inline-grid;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                padding: 0;
                justify-items: center;

                &::after {
                    content: "";
                    background-color: var(--dark);
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 3px;
                    opacity: 0;
                    left: 45%;
                }

                .icon_active {
                    display: none;

                    path {
                        fill: var(--dark);
                    }
                }

                svg {
                    width: 24px;
                    height: 24px;

                    path {
                        stroke: var(--dark);
                    }
                }

                i {
                    font-size: 24px;
                    color: var(--dark);
                }

                .img_profile {
                    width: 30px;
                    height: 30px;
                    border: 1px solid var(--white);
                    border-radius: 50%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .items_basket {
                    min-width: 51px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background-color: $red;
                    color: var(--white);
                    font-size: 11px;
                    border-radius: 4px;
                    top: -16px;
                    z-index: 10;
                    bottom: env(safe-area-inset-bottom);

                    @include prefixer(box-shadow, 0px 7px 20px 0px rgba($red, 0.2), webkit moz o ms);

                    &::before {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='72' height='67' viewBox='0 0 72 67'%3E%3Cdefs%3E%3Cfilter id='Polygon_2' x='0' y='0' width='72' height='67' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='7' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='10' result='blur'/%3E%3CfeFlood flood-color='%23556fff' flood-opacity='0.2'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Polygon_2)'%3E%3Cpath id='Polygon_2-2' data-name='Polygon 2' d='M5.241.886a1,1,0,0,1,1.519,0l3.826,4.463A1,1,0,0,1,9.826,7H2.174a1,1,0,0,1-.759-1.651Z' transform='translate(42 30) rotate(180)' fill='%23ff4040'/%3E%3C/g%3E%3C/svg%3E%0A");
                        background-size: 50px;
                        width: 50px;
                        height: 26px;
                        position: absolute;
                        bottom: -9px;
                    }
                }

                .items_basket_circle {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: $red;
                    font-size: 11px;
                    border: 2px solid var(--white);
                    color: var(--white);
                    top: 7px;
                    margin-left: -5px;
                }

                .btnCircle {
                    position: relative;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background-color: $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    margin: 0;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        border: 1px solid rgba($primary, 0.3);
                        border-radius: 50%;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        width: 52px;
                        height: 52px;
                        border: 1px solid rgba($primary, 0.1);
                        border-radius: 50%;
                    }

                    i {
                        font-size: 17px;
                        color: var(--white);
                    }

                    svg {
                        width: 17px;
                        height: 17px;

                        path {
                            stroke: var(--white);
                        }
                    }

                    &.bg-white {
                        background-color: var(--white);

                        &::before {
                            border: 1px solid rgba($red, 0.3);
                        }

                        &::after {
                            border: 1px solid rgba($red, 0.1);
                        }

                        i {
                            font-size: 17px;
                            color: var(--dark);
                        }

                        svg {
                            width: 17px;
                            height: 17px;

                            path {
                                stroke: var(--dark);
                            }
                        }
                    }
                }

                .btnCircle_default {
                    position: relative;
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    background-color: $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    margin: 0;

                    i {
                        font-size: 21px;
                        color: $white;
                    }

                    svg {
                        width: 21px;
                        height: 21px;

                        path {
                            stroke: $white;
                        }
                    }

                    &._lg {
                        width: 60px;
                        height: 60px;
                        margin-top: -22px;

                        i {
                            font-size: 26px;
                            color: $white;
                        }

                        svg {
                            width: 26px;
                            height: 26px;

                            path {
                                stroke: $white;
                            }
                        }
                    }

                    &.bg-white {
                        background-color: var(--white);

                        &::before {
                            border: 1px solid rgba($red, 0.3);
                        }

                        &::after {
                            border: 1px solid rgba($red, 0.1);
                        }

                        i {
                            font-size: 17px;
                            color: var(--dark);
                        }

                        svg {
                            width: 17px;
                            height: 17px;

                            path {
                                stroke: var(--dark);
                            }
                        }
                    }
                }

                &.btn_profile {
                    &.active {
                        &::after {
                            opacity: 0;
                        }
                    }
                }

                &.active {
                    &::after {
                        opacity: 1;
                    }

                    .icon_current {
                        display: none;
                    }

                    .icon_active {
                        display: block;
                    }

                    .img_profile {
                        border: 1px solid var(--gray-200);

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    &.with__text {
        .em_body_navigation {
            min-height: 60px;

            .item_link {
                height: 60px;

                .btn_navLink {
                    display: grid;

                    .items_basket_circle {
                        top: 10px;
                        margin-left: 0;
                    }

                    .txt__tile {
                        font-size: 11px;
                        font-weight: 400;
                        display: block;
                        padding-top: 3px;
                        color: var(--dark);
                    }

                    &::after {
                        margin-left: 12px;
                        top: -1px;
                    }
                }
            }

            &.-active_primary {
                .item_link {
                    .btn_navLink {
                        &.active {
                            .txt__tile {
                                color: $primary;
                            }

                            svg {
                                path {
                                    fill: $primary;
                                    stroke: $primary;
                                }
                            }
                        }

                        &::after {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }

    &.rounded {
        .em_body_navigation {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }

    &.just_color {
        .em_body_navigation {
            .item_link {
                .btn_navLink {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .em_body_navigation {
        &.bg-blue {
            background-color: $blue !important;
        }

        &.bg-green {
            background-color: $green !important;
        }

        &.bg-yellow {
            background-color: $yellow !important;
        }

        &.bg-orange {
            background-color: $orange !important;
        }

        &.bg-black {
            background-color: var(--bg-black) !important;
        }

        &.bg-primary,
        &.bg-secondary,
        &.bg-blue,
        &.bg-orange,
        &.bg-yellow,
        &.bg-green,
        &.bg-black {
            border-top: 0;

            .item_link {
                .btn_navLink {
                    .items_basket_circle {
                        background-color: var(--white);
                        color: var(--dark);
                    }

                    .txt__tile {
                        color: var(--white);
                    }

                    .icon_current {
                        svg {
                            path,
                            circle {
                                stroke: var(--white);
                            }
                        }
                    }

                    .icon_active {
                        svg {
                            path,
                            circle {
                                fill: var(--white);
                                stroke: var(--white);
                            }
                        }
                    }

                    i {
                        color: var(--white);
                    }

                    &::after {
                        background-color: var(--white);
                    }
                }
            }
        }
    }

    &.ouline_footer {
        .em_body_navigation {
            .item_link {
                .btn_navLink {
                    .txt__tile {
                        color: var(--secondary);
                    }

                    svg {
                        path {
                            stroke: var(--secondary);
                        }
                    }

                    .icon_current {
                        display: block !important;
                    }

                    &.active {
                        &::after {
                            opacity: 1;
                        }

                        .txt__tile {
                            color: var(--dark);
                        }

                        svg {
                            path {
                                stroke: var(--dark);
                            }
                        }
                    }

                    &.without_active {
                        svg {
                            path {
                                stroke: var(--white);
                            }
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.filter-blur {
        .em_body_navigation {
            background: rgba(255, 255, 255, 0.8);
            -webkit-backdrop-filter: saturate(1) blur(20px);
            -o-backdrop-filter: saturate(1) blur(20px);
            -ms-backdrop-filter: saturate(1) blur(20px);
            backdrop-filter: saturate(1) blur(20px);
        }
    }
}
